import React from "react";
import { Group, Text, Image, Container, Box, Burger } from "@mantine/core";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo_necchi.png";
import { useDisclosure } from "@mantine/hooks";

const links = [
  { link: "/support", label: "Assistenza tecnica" },
  { link: "/privacy", label: "Trattamento dati" },
];

const items = links.map((item, index) => (
  <Link
    to={item.link}
    key={index}
    color="white"
    style={{ marginRight: "1rem" }}
  >
    <Text fw="500">{item.label}</Text>
  </Link>
));
function Header() {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    <header className="bg-secondary-300 text-gray-800 py-5">
      <Container
        size="lg"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Group align="flex-end">
          <Link to="/">
            <Image
              src={logo}
              alt="Logo NECCHI"
              h={35} // Regola questa dimensione in base alle tue necessità
            />
          </Link>
          <Text>Customer Care</Text>
        </Group>
        <Box visibleFrom="sm" align="flex-end">
          <Group align="flex-end">{items}</Group>
        </Box>
        <Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm" />
      </Container>
    </header>
  );
}

export default Header;
