import React from "react";
import { Text, Container } from "@mantine/core";
function Footer() {
  return (
    <footer className="bg-gray-600 text-gray-200 p-4 mt-8">
      <Container size="lg">
        <div className="container mx-auto">
          <Text size="sm" fw={700}>
            BTV srl
          </Text>
          <Text size="sm">
            Via Variante di Cancelliera snc, 00072 Ariccia (RM)
          </Text>
          <Text>
            P. Iva 14873971007 – Tel. +39.06.93492003 - info@necchi.it
          </Text>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
