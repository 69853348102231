import { createTheme } from "@mantine/core";

const theme = createTheme({
    colorScheme: "dark",
    colors: {
        primary: [
            "#fff1f1",
            "#ffe4e4",
            "#ffccce",
            "#fea3a7",
            "#fd6f78",
            "#f73c4d",
            "#e41a36",
            "#c8102e",
            "#a1102c",
            "#8a112c",
            "#4d0413",
        ],
        secondary: [
            "#fdfce5",
            "#f8f6d3",
            "#f0ecaa",
            "#e7e17c",
            "#e0d957",
            "#dbd33e",
            "#d9d02f",
            "#c0b820",
            "#aaa316",
            "#938c03"
        ],
    },

    primaryColor: "secondary",
    secondaryColor: "primary",
    autoContrast: true,
});

export default theme;
