import React, { Suspense } from "react";
import { MantineProvider, Container } from "@mantine/core";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import theme from "./theme/theme";
import { Notifications } from '@mantine/notifications';
const HomePage = React.lazy(() => import("./pages/Home"));
const SupportPage = React.lazy(() => import("./pages/SupportPage"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const SupportRequest = React.lazy(() => import("./pages/SupportRequest"));
const CheckVoucher = React.lazy(() => import("./pages/CheckVoucher"));

function App() {
    return (
        <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
            <Notifications />
            <Router>
                <div className="App flex flex-col min-h-screen">
                    <Header />
                    <main className="flex-grow">
                        <Suspense fallback={<Container><div>Caricamento...</div></Container>}>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/support" element={<SupportPage />} />
                                <Route path="/support/:id/:requestNumber/checkVoucher" element={<CheckVoucher />} />
                                <Route path="/support/:id/:requestNumber" element={<SupportRequest />} />
                                <Route path="/privacy" element={<Privacy />} />
                            </Routes>
                        </Suspense>
                    </main>
                    <Footer />
                </div>
            </Router>
        </MantineProvider>
    );
}

export default App;
